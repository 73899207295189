import type { ComponentPropsWithoutRef } from "react";

import { cn } from "~/utils/classnames";

export function Card({ children, className, ...props }: ComponentPropsWithoutRef<"div">) {
  return (
    <div
      className={cn("relative rounded-xl border border-grey-200 bg-white p-4 shadow", className)}
      {...props}
    >
      {children}
    </div>
  );
}
